import { memo } from 'react';
import classnames from 'classnames';

import styles from './index.module.scss';

import BackButton from '@/components/BackButton/BackButton';
import Column from '@/components/Column/Column';
import Columns from '@/components/Columns/Columns';
import Head from '@/components/Head/Head';
import RichText from '@/components/RichText/RichText';

type Props = {
  className: string;
};

function NotFound({ className }: Props) {
  return (
    <main className={classnames(styles.NotFound, className)}>
      <Head title="Not Found" />
      <Columns>
        <Column />
        <Column>
          <RichText className={styles.title} tag="h1">
            404 Error
          </RichText>
          <RichText className={styles.content} tag="p" j3Hyphen={true}>
            Whatever you were looking for was not found.
          </RichText>
          <BackButton withBorder={true} url="/" text="Return to homepage" />
        </Column>
      </Columns>
    </main>
  );
}

export default memo(NotFound);
