import { memo, MutableRefObject, PropsWithChildren, useEffect, useRef } from 'react';
import classnames from 'classnames';
import gsap from 'gsap';
import { SplitText } from 'gsap/dist/SplitText';

import styles from './Column.module.scss';

export type Props = PropsWithChildren<{
  className?: string;
  verticalAlignment?: 'top' | 'center' | 'bottom';
}>;

const elementsToAnimate = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'p',
  'ul',
  'ol',
  '.ThemedButton',
  '.BackButton',
  '.ScrollButton',
  '.Quote',
  '.bio_investments_items'
];

function Column({ className, verticalAlignment, children }: Props) {
  const columnRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const q = gsap.utils.selector(columnRef.current);
    const elements = q(elementsToAnimate.join(','));

    // NOTE: ignore elements with data-attribute data-no-animation, needed for some nested components
    const filteredElements = elements?.filter((element) => !element.dataset.noAnimation);

    const timelineRef = gsap.timeline({
      paused: true,
      delay: 0.2,
      scrollTrigger: {
        trigger: columnRef.current,
        start: 'top 70%'
      }
    });
    const splitTextRef: SplitText[] = [];

    filteredElements.forEach((element, index) => {
      // NOTE: Only split text h1, h2 or h3
      if (element.tagName === 'H1' || element.tagName === 'H2' || element.tagName === 'H3') {
        splitTextRef[index] = new SplitText(element, { type: 'words' });
        splitTextRef[index].split({ type: 'lines' });
        const lines = splitTextRef[index].lines;

        gsap.effects.setupFadeIn(lines);
        const fade = gsap.effects.fadeIn(lines, { stagger: 0.1 });
        const translate = gsap.effects.translateIn(lines, { stagger: 0.1 });

        timelineRef.add(fade, index * 0.3);
        timelineRef.add(translate, index * 0.3);
      } else {
        gsap.effects.setupFadeIn(element);
        const fade = gsap.effects.fadeIn(element);
        const translate = gsap.effects.translateIn(element);

        timelineRef.add(fade, index * 0.05);
        timelineRef.add(translate, index * 0.05);
      }
    });

    timelineRef.add(gsap.set(columnRef.current, { opacity: 1 }), 0);

    return () => {
      timelineRef?.scrollTrigger?.kill();
      timelineRef?.kill();
      splitTextRef?.forEach((splitText) => splitText?.revert());
    };
  }, []);

  return (
    <div
      className={classnames(styles.Column, className, {
        [styles.alignCenter]: verticalAlignment === 'center',
        [styles.alignBottom]: verticalAlignment === 'bottom'
      })}
      ref={columnRef}
    >
      {children}
    </div>
  );
}

export default memo(Column);
