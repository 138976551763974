import { memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './RichText.module.scss';

import parseHtml from '@/utils/parser';
import scrollPage from '@/utils/scroll-page'

export type Props = PropsWithChildren<{
  className?: string;
  align?: 'left' | 'center' | 'right';
  textColor?: string;
  tag?: string;
  children: string;
  j3Hyphen?: boolean;
  anchor?: string;
}>;

function RichText({ children, className, align, textColor, tag = 'p', j3Hyphen, anchor }: Props) {
  const Tag = `${tag}` as keyof JSX.IntrinsicElements;
  const tagClassName = tag;

  // Handle click events on the RichText container
  function handleClick(event: React.MouseEvent) {
    //event.preventDefault();
    // Check if the clicked element is an anchor tag
    const target = event.target as HTMLElement;
    if (target.tagName.toLowerCase() === 'a') {
      const href = target.getAttribute('href');

      // Check if the href is an anchor link (starts with #)
      if (href && href.startsWith('#')) {
        event.preventDefault(); // Prevent default anchor behavior

        // Get the target element's ID
        const targetId = href.substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          // Use the smoothScroll utility to scroll to the target element
          scrollPage({
            y: targetElement.offsetTop,
            duration: 1, // Duration in seconds
            ease: 'power2.out' // Easing function
          });
        }
      }
    }
  };

  return (
    <Tag
      id={anchor}
      onClick={handleClick}
      className={classnames(styles.RichText, className, styles?.[tagClassName], {
        [`has-text-align-${align}`]: align,
        [`has-${textColor}-color`]: textColor,
        'line-indicator': j3Hyphen
      })}
    >
      {parseHtml(children ?? '')}
    </Tag>
  );
}

export default memo(RichText);
